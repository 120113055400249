body {
  background-image: url('../images/dark-bg.jpg');
  background-position: center center;
}

body button:hover {
  border: 1px solid #7453fc;
}

nav button {
  height: 46px !important;
  width: 120px !important;
}
